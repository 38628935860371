import { BackendURL, listOfAvatars, pathOfAvatars } from "src/config/settings";

export function randomColor() {
	let hex = Math.floor(Math.random() * 0xFFFFFF);
	return "#" + hex.toString(16);
}

export function getImage(filename) {
	if (!filename)
		return null;
	return `${BackendURL}public/${filename}`;
}

export function getRandomAvatar() {
	const rand = listOfAvatars[Math.floor(Math.random() * listOfAvatars.length)];
	return `${pathOfAvatars}${rand}`;
}