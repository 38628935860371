import GenericService from './GenericService';

class ServiceClass extends GenericService {
  constructor() {
    super();
  }

  getActive = () => this.get('/projects');

  getClosed = () => this.get('/projects?closed=1');

  add = (data) => this.post('/projects', data);

  close = (ID, data) => this.put(`/projects/${ID}`, { closed: data });

  remove = (ID) => this.delete(`/projects/${ID}`);

}

const projectService = new ServiceClass();

export default projectService;
