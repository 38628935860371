/*
    Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/*
    Imports:
        Material UI
*/
import { Button, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
/*
    Imports:
        Our Imports
        Components and Settings
        Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddPolicySchema, AddProjectSchema } from 'src/config/form-schemas';
import { RoutePolicy } from 'src/config/routes';
import projectService from 'src/services/ProjectServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import NumberFormat from 'react-number-format';
import policyService from 'src/services/PolicyServiceClass';

/*
    Main Working
*/
export default ({ editing, editingData, policyID }) => {

    /*
        States, Params, Navigation, Query, Variables.
    */
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);

    const navigate = useNavigate();

    /*
        Form Setup
    */
    const formik = useFormik({
        initialValues: {
            name: '',
            stake: 0,
            shareable: 0,
            divisions: [
                {
                    name: "",
                    category: '',
                    amount: 0
                }
            ]
        },
        validationSchema: AddPolicySchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError
    } = formik;

    /*
        Handlers
    */
    const addData = () => {
        setSubmitting(true);


        for (const index in values.divisions)
            if (values.divisions[index].category == '') delete values.divisions[index]

        let FuncToCall = policyService.add
        if (editing) FuncToCall = policyService.update

        FuncToCall({ ...values }, policyID)
            .then(() => {
                console.log(values);
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RoutePolicy);
    };

    const handleEditing = () => {
        if (editing) {
            setFieldValue('name', editingData.name);
            setFieldValue('stake', editingData.stake);
            setFieldValue('shareable', editingData.shareable);
            showDivisions(editingData.divisions)
        }
    }

    const showDivisions = (divisions) => {
        const u = [];
        console.log(divisions)
        divisions?.shareable.map((a) => {
            if (a.amount > 0) {
                console.log(a)
                u.push({
                    name: a.name,
                    category: a.category,
                    amount: a.amount,
                });
            }
            setFieldValue('divisions', u);
        });
        divisions?.stake.map((a) => {
            if (a.amount > 0) {
                u.push({
                    name: a.name,
                    category: a.category,
                    amount: a.amount,
                });
            }
            setFieldValue('divisions', u);
        });
    }

    /*
        Use Effect Hooks.
    */

    useEffect(handleEditing, [])

    // useEffect(handleUsersChange, [values.divisions]);


    /*
        Main Design
    */
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Policy Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Stake</InputLabel>
                            </ThemeProvider>
                            <NumberFormat
                                fullWidth
                                customInput={TextField}
                                type="text"
                                allowEmptyFormatting="true"
                                inputProps={{
                                    inputMode: 'numeric',
                                }}

                                {...getFieldProps('stake')}
                                error={Boolean(touched.stake && errors.stake)}
                                helperText={touched.stake && errors.stake}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Shareable</InputLabel>
                            </ThemeProvider>
                            <NumberFormat
                                fullWidth
                                customInput={TextField}
                                type="text"
                                allowEmptyFormatting="true"
                                inputProps={{
                                    inputMode: 'numeric',
                                }}

                                {...getFieldProps('shareable')}
                                error={Boolean(touched.shareable && errors.shareable)}
                                helperText={touched.shareable && errors.shareable}
                            />
                        </Grid>
                    </Grid>
                </ContentStyle>

                <FieldArray name="divisions">
                    {(arrayHelpers) =>
                        <>

                            {values.divisions.map((user, index) => (
                                <ContentStyle>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Category</InputLabel>
                                            </ThemeProvider>
                                            <Select fullWidth
                                                {...getFieldProps(`divisions.${index}.category`)}
                                            >
                                                {
                                                    values.shareable > 0 ?
                                                        (<MenuItem key="shareable" value="shareable">Shareable</MenuItem>)
                                                        :
                                                        <></>
                                                }{
                                                    values.stake > 0 ?
                                                        (< MenuItem key="stake" value="stake">Stake</MenuItem>)
                                                        :
                                                        <></>
                                                }
                                            </Select>
                                            <FormHelperText error>{
                                                errors?.divisions?.length > 0 && errors.divisions[index]?.category
                                            }</FormHelperText>
                                        </Grid>

                                        <Grid item xs={12} sm={3} md={3}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Name</InputLabel>
                                            </ThemeProvider>
                                            <TextField
                                                fullWidth
                                                autoComplete="name"
                                                {...getFieldProps(`divisions.${index}.name`)}
                                            />
                                            <FormHelperText error>{
                                                errors?.divisions?.length > 0 && errors.divisions[index]?.name
                                            }</FormHelperText>

                                        </Grid>


                                        <Grid item xs={12} sm={3} md={3}>
                                            <ThemeProvider theme={FormTheme}>
                                                <InputLabel>Amount</InputLabel>
                                            </ThemeProvider>
                                            <NumberFormat
                                                fullWidth
                                                customInput={TextField}
                                                type="text"
                                                allowEmptyFormatting="true"
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            %
                                                        </InputAdornment>
                                                    )
                                                }}
                                                {...getFieldProps(`divisions.${index}.amount`)}
                                            />
                                            <FormHelperText error>{
                                                errors?.divisions?.length > 0 && errors.divisions[index]?.amount
                                            }</FormHelperText>
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1}>
                                            <ContentStyle align="end">
                                                <Button onClick={() => arrayHelpers.remove(index)} >
                                                    Remove
                                                </Button>
                                            </ContentStyle>
                                        </Grid>
                                    </Grid>
                                </ContentStyle>
                            )
                            )}
                            <ContentStyle align="right">
                                < Button onClick={() => arrayHelpers.push({ name: '', category: '', amount: 0 })} variant="contained" >
                                    Add
                                </Button>
                            </ContentStyle>

                        </>
                    }

                </FieldArray>

                <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                    {editing ? 'Policy is updated' : `Policy is added`}
                </Dialog>

                <LoadingFormButton loading={isSubmitting}>{editing ? 'Edit' : 'Add'}</LoadingFormButton>
                <ServerError open={serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider >
    );
};
