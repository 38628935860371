/*
  Imports
*/
import {
  Avatar,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import { useState } from 'react';

import { DefaultAvatar, FaviconImage, LogoImage, TITLE } from 'src/config/settings';
import { fNumber } from 'src/utils/formatNumber';
import { getImage } from 'src/utils/misc';
import { getComparator, stabilize } from 'src/utils/table';
import { UserListHead } from '../../../components/misc/table';
import Scrollbar from '../../../components/Scrollbar';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator) {
  return stabilize(array, comparator);
}

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const TableHeader = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'center' }
  ];

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(TableHeader[1].id);

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy));

  /*
    Handlers, Functions
  */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  /*
    Main Design.
  */
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TableHeader}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              hideCheckBoxes={true}
            />
            <TableBody>
              {filteredUsers.map((row, index) => {
                const { name, amount, category, image } = row;
                return (
                  <TableRow key={index} tabIndex={-1} role="checkbox">
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell component="th" scope="row" padding="none">
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Avatar
                          alt={name}
                          src={(name ? getImage(image) : LogoImage) || DefaultAvatar}
                        />
                        <Typography variant="subtitle2" noWrap>
                          {name || TITLE}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="left">{category}</TableCell>
                    <TableCell align="center">Rs. {fNumber(amount)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
};
