/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';
import { fNumber } from 'src/utils/formatNumber';

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const { amount, shareable, stake, donation } = data;
  /*
    Main Design
  */
  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20, paddingTop: 20, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={1}>
          <Typography fontWeight="bold">Total Amount: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Typography>Rs. {fNumber(amount)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={1}>
          <Typography fontWeight="bold">Total Shareable: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Typography>Rs. {fNumber(shareable)}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: 10, paddingTop: 10, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={1}>
          <Typography fontWeight="bold">Total Stake: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Typography>Rs. {fNumber(stake)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={1}>
          <Typography fontWeight="bold">Total Donation: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={5}>
          <Typography>Rs. {fNumber(donation)}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
