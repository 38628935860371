/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';



import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import policyService from 'src/services/PolicyServiceClass';
import Page from '../components/Page';
import DivisonsHeader from './header/DivisonsHeader';
import DivisionsList from './tables/DivisionsList';
/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  const navigate = useNavigate();
  const [data, setData] = useState({ name: '' });

  const title = `${data.name} Divisions`;

  /*
      Handlers, Functions
    */
  const getData = () => {

    setLoading(true);
    policyService.getType(id)
      .then((res) => {
        console.log("Res", res);
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, []);

  /*
    Handlers, Functions
  */
  const handleAdd = () => {
    navigate('./add');
  };

  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>

        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <ListPageTitle >{title}</ListPageTitle>
            <DivisonsHeader data={data} />
            <DivisionsList data={[...data.divisions.shareable, ...data.divisions.stake]} />
          </>

        )}
        {/* <FloatingAdd tooltip='add new payment' onClick={handleAdd} /> */}
      </Container>
    </Page>
  );
};
