/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';



import { useEffect, useState } from 'react';
import FloatingAdd from 'src/components/misc/Buttons/FloatingAdd';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import userService from 'src/services/UserService';
import Page from '../components/Page';
import UsersList from './tables/MembersList';

/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const title = "Members";

  /*
      Handlers, Functions
    */
  const getData = () => {
    setLoading(true);
    userService.getAll()
      .then((res) => {
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, []);

  /*
    Handlers, Functions
  */
  const handleAdd = () => {
    navigate('./add');
  };

  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>
        <ListPageTitle >{title}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <UsersList data={data} />
        )}
        <FloatingAdd tooltip='add new member' onClick={handleAdd} />
      </Container>
    </Page>
  );
};
