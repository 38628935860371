import GenericService from './GenericService';

class ServiceClass extends GenericService {
  constructor() {
    super();
  }

  getTypes = () => this.get('/policies/types');

  getType = (ID) => this.get(`/policies/types/${ID}`);

  add = (data, id) => this.post('/policies', data);

  update = (data, id) => this.put(`/policies/${id}`, data);

}

const policyService = new ServiceClass();

export default policyService;
