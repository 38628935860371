export const TITLE = 'East Devs';
export const fullTitle = 'East Devs';
export const rowsPerPageList = [10, 15, 30, 50, 100];
export const defaultPerPage = 10;
export const BackendURL = 'https://server.eastdevs.com/'; //Hosting
// export const BackendURL = 'http://127.0.0.1:1337/'; //Local
// export const BackendURL = 'http://localhost:1337/';
export const BackendURLAPI = BackendURL + 'api/';

export const InternetCheckInterval = 5 * 60 * 1000;

export const LogoImage = '/static/logo.png';
export const FaviconImage = '/favicon/logo.png';
export const UserLoginImage = '/static/illustrations/user.png';
export const ForgetPageImage = '/static/illustrations/forgot.png';
export const ResetPageImage = ForgetPageImage;
export const Image404 = '/static/illustrations/404.png';
export const Image401 = '/static/illustrations/401.webp';
export const DefaultAvatar = '/static/mock-images/avatars/avatar_default.png';

export const pathOfAvatars = '/static/mock-images/avatars/';
export const listOfAvatars = [
    'avatar_default.png',
    'avatar_1.jpg',
    'avatar_19.jpg',
    'avatar_20.jpg',
    'avatar_21.jpg',
    'avatar_22.jpg',
    'avatar_23.jpg',
    'avatar_24.jpg'
];

export const DefaultUploadedFileImage = '/static/icons/file.png';
export const NotificationSound = '/static/sounds/notification.mp3';

export const NotificationReload = 1000 * 30;
export const DataReload = NotificationReload;

export const allowedExtensions = [
    'png',
    'jpg',
    'jpeg',
    'mp3',
    'pdf',
    'zip',
    'rar',
    'docx',
    'doc',
    'ppt',
    'pptx',
    'txt'
];

export const acceptImageUpload = 'image/png, image/jpg, image/jpeg';
export const acceptFileUpload =
    'image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt';
export const maxUploadFileSize = 10 * 1024 * 1024; //10 Mbs
export const hideFileAlertIn = 3000;
