/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddMemberForm from './forms/AddMemberForm';

/*
    Main Working
*/
export default () => {

  /*
    Main Design
  */
  return (
    <Page title={`Add Member`} >
      <Container maxWidth="xl">
        <ListPageTitle>
          {"Adding Member"}
        </ListPageTitle>
        <AddMemberForm />
      </Container>
    </Page >
  );
};
