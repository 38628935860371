/*
    Imports
*/
import { Container } from '@material-ui/core';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import AddProjectForm from './forms/AddProjectForm';

/*
    Main Working
*/
export default () => {

  /*
    Main Design
  */
  return (
    <Page title={`Add Project`} >
      <Container maxWidth="xl">
        <ListPageTitle>
          {"Adding Project"}
        </ListPageTitle>
        <AddProjectForm />
      </Container>
    </Page >
  );
};
