/*
  Imports
*/
import {
  Avatar,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Dialog from 'src/components/misc/alerts/Dialog';
import { ProjectsClosedIcon, ProjectsOpenIcon, TrashIcon } from 'src/config/icons';
import { RoutePayments } from 'src/config/routes';
import { DefaultAvatar, defaultPerPage, rowsPerPageList } from 'src/config/settings';
import projectService from 'src/services/ProjectServiceClass';
import palette from 'src/theme/palette';
import { getDateString } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import { getImage } from 'src/utils/misc';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
  let toSort = array;
  if (query) {
    toSort = filter(
      array,
      (element) =>
        element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        element.user.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(element.totalAmount).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        getDateString(element.addedOn).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        getDateString(element.lastPayment).toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data, reload }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const TableHeader = [
    { id: 'name', label: 'Title', align: 'left' },
    { id: 'user', label: 'Finder', align: 'left' },
    { id: 'addedOn', label: 'Started On', align: 'left' },
    { id: 'totalAmount', label: 'Total Amount', align: 'center' },
    { id: 'lastPayment', label: 'Last Payment', align: 'left' }
  ];

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState(TableHeader[4].id);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(defaultPerPage);

  const [showDelete, setShowDelete] = useState(-1);

  const navigate = useNavigate();

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  /*
    Handlers, Functions
  */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /*
    Table View More Variables
  */

  const handleLock = (ID) => {
    setShowDelete(ID);
  };
  const handleOpen = (ID) => {
    projectService
    .close(ID, 0)
    .then(reload)
    .catch((_error) => {
      //TODO: error handling here
    })  };

  const handleClose = () => {
    setShowDelete(-1);
  };

  const handleDeleteFinal = () => {
    projectService
      .close(showDelete, 1)
      .then(reload)
      .catch((_error) => {
        //TODO: error handling here
      })
      .finally(() => setShowDelete(-1));
  };

  const handleMoreMenuCell = (event) => {
    event.preventDefault();
  };

  const handleRemove = (ID) => {
    projectService
      .remove(ID)
      .then(reload)
      .catch((err)=> console.log(err))
  }

  /*
    Table View More Variables
  */

  const MORE_MENU_CLOSED = [{ text: 'Open', icon: ProjectsOpenIcon, event: handleOpen, id: 0 }];
  const MORE_MENU = [{ text: 'Close', icon: ProjectsClosedIcon, event: handleLock, id: 0 }];
  const MORE_MENU_NOPAYMEMTS = [
    { text: 'Close', icon: ProjectsClosedIcon, event: handleLock, id: 0 },
    { text: 'Delete', icon: TrashIcon, event: handleRemove, id: 0 }
];

  /*
    Use Effect Hooks.
  */

  /*
    Main Design.
  */
  return (
    <>
      <Card>
        <UserListToolbar
          numSelected={selected.length}
          filterName={filterName}
          onFilterName={handleFilterByName}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TableHeader}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                onSelectAllClick={handleSelectAllClick}
                hideCheckBoxes={true}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    const { id, name, user, addedOn, lastPayment, totalAmount, image, closed } =
                      row;
                    const isItemSelected = selected.indexOf(id) !== -1;

                    return (
                      <TableRow
                        hover
                        component={Link}
                        to={`${RoutePayments}?project=${id}`}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                        style={{
                          backgroundColor: palette.background.hover,
                          textDecoration: 'none'
                        }}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              /> */}
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={user} src={getImage(image) || DefaultAvatar} />
                            <Typography variant="subtitle" noWrap>
                              {user}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left"> {getDateString(addedOn)}</TableCell>
                        <TableCell align="center">Rs. {fNumber(totalAmount)}</TableCell>
                        <TableCell align="left"> {getDateString(lastPayment)}</TableCell>
                        {!closed && (
                          <TableCell align="right" onClick={handleMoreMenuCell}>
                            <UserMoreMenu ID={[id]} Options={lastPayment==null ? MORE_MENU_NOPAYMEMTS : MORE_MENU} />
                          </TableCell>
                        )}
                        {!!closed && (
                          <TableCell align="right" onClick={handleMoreMenuCell}>
                            <UserMoreMenu ID={[id]} Options={MORE_MENU_CLOSED} />
                          </TableCell>
                        )
                        }
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={rowsPerPageList}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      <Dialog
        warning
        buttonText={'Close'}
        buttonText2={'Lock'}
        openDialog={showDelete != -1}
        handleButton={handleClose}
        handleButton2={handleDeleteFinal}
      >
        Are you sure you want to close the project?
      </Dialog>
    </>
  );
};
