import { Icon } from '@iconify/react';
import {
  CoordinatorsIcon,
  CoursesIcon,
  DashboardIcon,
  ExpensesIcon,
  FeeIcon,
  MadarisIcon,
  PastClassesIcon,
  PaymentsIcon,
  PolicyIcon,
  ProjectsClosedIcon,
  ProjectsIcon,
  StudentsIcon,
  TeachersIcon,
  UsersIcon
} from 'src/config/icons';

import {
  RouteAdminDashboard, RouteAdminFee,
  RouteCoordinatorFee, RouteCoordinators,
  RouteCourses,
  RouteDashboard,
  RouteMadaris, RoutePastClasses, RouteClosedProjects, RouteProjects, RouteSearchStudent,
  RouteSearchTeacher, RouteStudentDashboard,
  RouteStudentResult, RouteTeacherDashboard, RoutePayments, RouteExpense, RouteUsers, RoutePolicy
} from 'src/config/routes';

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

export const sidebarConfig = {
  top: [
    {
      title: 'dashboard',
      path: RouteDashboard,
      icon: getIcon(DashboardIcon)
    },
    {
      title: 'members',
      path: RouteUsers,
      icon: getIcon(UsersIcon)
    },
    {
      title: 'projects',
      path: RouteProjects,
      icon: getIcon(ProjectsIcon)
    },
    {
      title: 'payments',
      path: RoutePayments,
      icon: getIcon(PaymentsIcon)
    },
    {
      title: 'expenses',
      path: RouteExpense,
      icon: getIcon(ExpensesIcon)
    },
    {
      title: 'policy',
      path: RoutePolicy,
      icon: getIcon(PolicyIcon)
    },
  ],

  bottom: [
    {
      title: 'closed projects',
      path: RouteClosedProjects,
      icon: getIcon(ProjectsClosedIcon)
    },
  ]
};

export const getCoordinatorSideBar = (coordinator) => {

  return [];
};
