/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import paymentService from 'src/services/PaymentServiceClass';
import projectService from 'src/services/ProjectServiceClass';
import userService from 'src/services/UserService';
import AddPaymentForm from './forms/AddPaymentForm';

/*
    Main Working
*/
export default () => {

  const navigate = useNavigate();

  const [types, setTypes] = useState();
  const [projects, setProjects] = useState();
  const [users, setUsers] = useState();

  const getData = () => {
    paymentService.getTypes()
      .then((data) => {
        setTypes(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');

      });

    projectService.getActive()
      .then((data) => {
        setProjects(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');

      });

    userService.getAll()
      .then((data) => {
        setUsers(data);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');

      });
  };

  useEffect(getData, []);

  const loading = !types || !projects || !users;

  /*
    Main Design
  */
  return (
    <Page title={`Add Project`} >
      <Container maxWidth="xl">
        <ListPageTitle>
          {"Adding Payment"}
        </ListPageTitle>
        {loading ?
          <CenterLoading />
          :
          <AddPaymentForm types={types} projects={projects} users={users} />
        }

      </Container>
    </Page >
  );
};
