import GenericService from './GenericService';

class StakeServiceClass extends GenericService {
  constructor() {
    super();
  }

  getBod = () => this.get('/users/bod');

  getNonBod = () => this.get('/users/nonbod');

}

const stakeService = new StakeServiceClass();

export default stakeService;
