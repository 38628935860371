/*
	Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/*
	Imports:
		Material UI
*/
import { Grid, InputLabel, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
/*
	Imports:
		Our Imports
		Components and Settings
		Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddProjectSchema } from 'src/config/form-schemas';
import { RouteProjects } from 'src/config/routes';
import projectService from 'src/services/ProjectServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';

/*
	Main Working
*/
export default () => {
    /*
		States, Params, Navigation, Query, Variables.
	*/
    const [serverError, setServerError] = useState('');
    const [openDia, setOpenDia] = useState(false);

    const navigate = useNavigate();

    /*
		Form Setup
	*/
    const formik = useFormik({
        initialValues: {
            name: '',
            addedOn: ''
        },
        validationSchema: AddProjectSchema,
        onSubmit: (_values, { setFieldError }) => {
            addData();
        }
    });

    const {
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        getFieldProps,
        setFieldValue,
        setSubmitting,
        resetForm,
        initialValues,
        setFieldError
    } = formik;

    /*
		Handlers
	*/
    const addData = () => {
        setSubmitting(true);
        projectService
            .add({ ...values })
            .then(() => {
                console.log('User Added');
                setOpenDia(true);
            })
            .catch((err) => {
                console.log('Error', err, err.response);
                if (err.response) {
                    for (const key in values) {
                        if (Object.hasOwnProperty.call(values, key)) {
                            if (err.response.data.message.includes(key))
                                setFieldError(key, 'Invalid or Already Added');
                        }
                    }
                }

                console.log('Error', err, err.response);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClose = () => {
        setOpenDia(false);
        navigate(RouteProjects);
    };

    /*
		Use Effect Hooks.
	*/

    /*
		Main Design
	*/
    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Typography variant="h6" gutterBottom>
                    Project Details
                </Typography>

                <ContentStyle>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel>Name</InputLabel>
                            </ThemeProvider>
                            <TextField
                                fullWidth
                                autoComplete="name"
                                {...getFieldProps('name')}
                                error={Boolean(touched.name && errors.name)}
                                helperText={touched.name && errors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <ThemeProvider theme={FormTheme}>
                                <InputLabel label="Address">Date</InputLabel>
                            </ThemeProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    inputFormat="yyyy/MM/dd"
                                    mask="____/__/__"
                                    value={values.addedOn}
                                    maxDate={new Date()}
                                    onChange={(newValue) => {
                                        setFieldValue('addedOn', newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            {...params}
                                            {...getFieldProps('addedOn')}
                                            error={Boolean(touched.addedOn && errors.addedOn)}
                                            helperText={touched.addedOn && errors.addedOn}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </ContentStyle>

                <Dialog buttonText={'Close'} openDialog={openDia} handleButton={handleClose}>
                    {`Project is added`}
                </Dialog>

                <LoadingFormButton loading={isSubmitting}>{'Add'}</LoadingFormButton>
                <ServerError open={serverError}>{serverError}</ServerError>
            </Form>
        </FormikProvider>
    );
};
