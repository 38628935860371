import railfencecipher from 'railfencecipher';
import qs from 'qs';
import GenericService from './GenericService';
import { RailFenceSize } from 'src/config/settings';

class UserService extends GenericService {

  login = (ID, Password) =>
    new Promise((resolve, reject) => {
      this.post('auth', {
        username: ID,
        password: Password
      })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  logout = () =>
    new Promise((resolve, reject) => {
      this.get('auth/logout')
        .then(() => {
          sessionStorage.removeItem("user");
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });


  getAll = () => this.get(`users`);

  isLoggedIn = async () =>
    new Promise((resolve, reject) => {
      this.get('/').then((res) => {
        const { user, loggedIn } = res;
        if (user)
          sessionStorage.setItem("user", JSON.stringify(user));
        console.log("Is Logged In", loggedIn);
        if (loggedIn)
          return resolve();
        reject("Not logged in");
      })
        .catch((err) => {
          reject(err);
        });
    });

  getLoggedInUser() {
    const sUser = sessionStorage.getItem("user");
    console.log("sUser", JSON.parse(sUser));
    return JSON.parse(sUser);
  }

  getUser = (ID) => this.get(`users/${ID}`);

  addUser = (data, file, onUploadProgress) =>
    this.postForm(`users/register`, data, file, onUploadProgress);


  updateMe = (data, file, onUploadProgress) =>
    this.putForm(`users`, data, file, onUploadProgress);

  forgetPassword = (data) => this.post('auth/forget', data);

  resetPassword = (data) => this.post('auth/reset', data);

}

export default UserService;
