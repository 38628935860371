/*
  Imports
*/
import { Button, Container, Stack } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import paymentService from 'src/services/PaymentServiceClass';
import Page from '../components/Page';
import TranscationHeader from './header/TranscationHeader';
import TranscationsList from './tables/TranscationsList';
import expenseService from 'src/services/ExpenseServiceClass';

/*
  Main Working
*/
export default ({ expense }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */

  const { id } = useParams();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [data, setData] = useState();
  const [disabled, setDisabled] = useState(false);

  const title = `${data?.project} Payment ${id}`;

  /*
      Handlers, Functions
    */
  const getData = () => {
    console.log('Transcation  ' + id);
    setLoading(true);

    (expense ? expenseService : paymentService)
      .getOne(id)
      .then((res) => {
        console.log('Res', res
        );
        setDisabled(res.unpaid <= 0)
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateAll = () => {
    console.log(id, data);
    paymentService
      .updateAllTranscations(id, 1)
      .then(() => {
        getData()
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        console.log(error.response)
      })
  }

  /*
      Use Effect Hooks.
    */
  useEffect(getData, [id]);

  /*
    Handlers, Functions
  */
  const handleAdd = () => {
    navigate('./add');
  };

  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <ListPageTitle>{title}</ListPageTitle>
            <TranscationHeader data={data} />
            <TranscationsList data={data?.details || []} reload={getData} />
            <Stack direction="row" marginTop={3} justifyContent="right">
              <Button
                style={{ padding: '0px 70px' }}
                size="large"
                type="submit"
                variant="contained"
                disabled={disabled}
                onClick={handleUpdateAll}
              >
                Payall
              </Button>
            </Stack>

          </>
        )}
      </Container>
    </Page>
  );
};
