import GenericService from './GenericService';

class ServiceClass extends GenericService {
  constructor() {
    super();
  }

  getAll = () => this.get('/expenses');

  getByProject = (ID) => this.get(`/expenses?project=${ID}`);

  getOne = (ID) => this.get(`/expenses/${ID}`);

  updateTranscation = (id, paid) => this.put(`/payments/transcations/${id}`, { paid });

  getTypes = () => this.get('/expenses/types');

  calculate = (data) => this.post('/expenses', data);

  add = (data) => this.post('/expenses?add=true', data);
}

const expenseService = new ServiceClass();

export default expenseService;
