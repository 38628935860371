
export const RouteLandingPage = '/';
export const RouteForgetPass = '/forget';
export const RouteResetPass = '/reset';
export const RouteLogin = '/login';
export const RouteDashboard = "/dashboard";
export const RouteProjects = "/projects";
export const RouteClosedProjects = "/cprojects";
export const RoutePayments = "/payments";
export const RouteExpense = "/expenses";
export const RouteUsers = "/users";
export const RoutePolicy = "/policy";
export const Route401 = "/401";