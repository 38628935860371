import * as Yup from 'yup';
import { cnicRegExp, courseCodeRegExp, feeAmountRegExp, madrisaCodeRegExp, nameRegExp, phoneRegExp, studentCodeRegExp } from './regex';

export const ResetSchema = (password) => (
	Yup.object().shape({
		password:
			Yup.string()
				.required('Password is required')
				.min(6, 'Password must be atleast 6 characters')
				.max(30, 'Password must not be more than 30 characters'),
		confirm:
			Yup.string()
				.equals([password], 'Passwords do not match')
				.required('Confirm Password is required')
	})
);

export const ForgetSchema = Yup.object().shape({
	email:
		Yup.string()
			.required('Email is required')
			.email('Valid Email is required')
});

export const LoginSchema = Yup.object().shape({
	username:
		Yup.string()
			.required('Username is required'),
	password:
		Yup.string()
			.required('Password is required')
});


export const AddUserSchema = Yup.object().shape({
	username:
		Yup.string()
			.required('Username is required'),
	name:
		Yup.string()
			.required('Name is required')
			.matches(nameRegExp, 'Enter a valid name'),
	email:
		Yup.string()
			.required('Email is required')
			.email('Enter correct email'),
	password:
		Yup.string()
			.required('Password is required'),
	dob:
		Yup.date()
			.required("Please enter date of birth")
			.test("dob", "Please choose a valid date of birth", (value) => {
				const d = new Date(value);
				return new Date().getFullYear() - d.getFullYear() >= 5;
			}),
});

export const AddProjectSchema = Yup.object().shape({
	name:
		Yup.string()
			.required('Name is required'),
});

export const AddPaymentSchema = Yup.object().shape({
	amount:
		Yup.number()
			.min(1)
			.required('Amount is required'),
});

export const AddExpenseSchema = Yup.object().shape({
	info:
		Yup.string()
			.required("Info is rquired"),
	amount:
		Yup.number()
			.min(1)
			.required('Amount is required'),
});

Yup.addMethod(Yup.object, 'calculations', function (propertyName, category, message) {
	return this.test('sum100', message, function (value) {

		if (!value || !value[propertyName]) {
			return true;
		}

		if (
			this.parent
				.filter(v => v.category == category)
				.reduce(((total, amount) => total += +amount.amount), 0) != 100
		) {
			if (this.originalValue.category == category)
				throw this.createError({
					path: `${this.path}.${propertyName}`,
				});
		}
		return true;
	});
});

export const AddPolicySchema = Yup.object().shape({
	name:
		Yup.string()
			.required("Name is required"),

	stake:
		Yup.number()
			.required("Stake amount is required")
			.min(0, "Enter correct percent")
			.max(100, "Enter correct percent"),

	shareable:
		Yup.number()
			.required("Shareable amount is required")
			.min(0, "Enter correct percent")
			.max(100, "Enter correct percent")
			.when('stake', (shareable, schema) => {
				return schema.test({
					test: (stake) => (stake + shareable) == 100,
					message: "Sum of stake and shareable should be 100"
				});
			}),

	divisions:
		Yup.array().of(
			Yup.object()
				.shape({
					name:
						Yup.string()
							.required("Name is required"),
					category:
						Yup.string()
							.required("Category is required"),
					amount:
						Yup.number()
							.required("Amount is required")
							.min(0, "Enter correct percent")
							.max(100, "Enter correct percent")
				})
				.calculations("amount", 'stake', "Sum of stake amounts should be 100")
				.calculations("amount", 'shareable', "Sum of shareable amounts should be 100")
		)
})




export const SettingsSchema = (password) => Yup.object().shape({
	// contact:
	// 	Yup.string()
	// 		.required('Contact is required')
	// 		.matches(phoneRegExp, 'Enter valid Contact Number'),
	email:
		Yup.string()
			.required('Email is required')
			.email('Enter correct email'),

	password:
		Yup.string()
			.min(6, 'Password must be atleast 6 characters')
			.max(30, 'Password must not be more than 30 characters'),
	confirm:
		Yup.string()
			.equals([password], 'Passwords do not match')
});
