/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';



import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import FloatingAdd from 'src/components/misc/Buttons/FloatingAdd';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import expenseService from 'src/services/ExpenseServiceClass';
import Page from '../components/Page';
import ExpensesHeader from './header/ExpensesHeader';
import ExpensesList from './tables/ExpensesList';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}
/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */

  const query = useQuery();
  const project = query.get("project");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const title = `${project ? (data[0]?.project + " " || "") : ""}Expenses`;

  /*
      Handlers, Functions
    */
  const getData = () => {
    console.log("Project " + project);
    setLoading(true);
    const f = project ? expenseService.getByProject : expenseService.getAll;
    f(project)
      .then((res) => {
        console.log("Res", res);
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, [project]);

  /*
    Handlers, Functions
  */
  const handleAdd = () => {
    navigate('./add');
  };

  let amount = 0,
    stake = 0,
    shareable = 0;

  for (const d of data) {
    d.unpaid = +d.unpaid;
    amount += +d.amount;
    stake += +d.stake;
    shareable += +d.shareable;
  }

  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>

        {loading ? (
          <CenterLoading />
        ) : (
          <>
            <ListPageTitle >{title}</ListPageTitle>
            {project && <ExpensesHeader data={{ amount, shareable, stake }} />}
            <ExpensesList data={data} reload={getData} />
          </>

        )}
        <FloatingAdd tooltip='add new payment' onClick={handleAdd} />
      </Container>
    </Page>
  );
};
