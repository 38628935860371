import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled';
import lockOutlined from '@iconify/icons-ant-design/lock-outlined';
import unlockOutlined from '@iconify/icons-ant-design/unlock-outlined';
import walletIcon from '@iconify/icons-entypo/wallet';
import successFilled from '@iconify/icons-ep/success-filled';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import rupeeSign from '@iconify/icons-fa6-solid/rupee-sign';
import crossCircle from '@iconify/icons-gridicons/cross-circle';
import outlineAccountCircle from '@iconify/icons-ic/outline-account-circle';
import outlineUploadFile from '@iconify/icons-ic/outline-upload-file';
import roundFileMove from '@iconify/icons-ic/round-drive-file-move';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import sharpUploadFile from '@iconify/icons-ic/sharp-upload-file';
import accountEye from '@iconify/icons-mdi/account-eye';
import atIcon from '@iconify/icons-mdi/at';
import CloudDownloadIcon from '@iconify/icons-mdi/download';
import password1Icon from '@iconify/icons-wpf/password1';

import briefcaseIcon from '@iconify/icons-mdi/briefcase';
import briefcaseCheck from '@iconify/icons-mdi/briefcase-check';
import briefcaseUpload from '@iconify/icons-mdi/briefcase-upload';

import moneyDismiss24Filled from '@iconify/icons-fluent/money-dismiss-24-filled';
import moneyBag from '@iconify/icons-healthicons/money-bag';

import policyIcon from '@iconify/icons-carbon/policy';

export const ViewIcon = accountEye;
export const ViewIcon2 = eyeFill;
export const EditIcon = editFill;
export const LockIcon = lockOutlined;
export const UnlockIcon = unlockOutlined;

export const UnpaidIcon = crossCircle;
export const DownloadIcon = CloudDownloadIcon;
export const UploadIcon = outlineUploadFile;
export const UploadIcon2 = sharpUploadFile;

export const SearchIcon = searchFill;
export const TrashIcon = trash2Fill;
export const FilterIcon = roundFilterList;
export const MoveIcon = roundFileMove;

export const SuccessDialogIcon = checkCircle;
export const ErrorDialogIcon = closeCircle;
export const WarningDialogIcon = exclamationCircle;
export const CNICIcon = outlineAccountCircle;
export const EmailIcon = atIcon;
export const ShowPasswordIcon = eyeOffFill;
export const HidePasswordIcon = eyeFill;
export const PasswordIcon = password1Icon;


export const DashboardIcon = pieChart2Fill;
export const bankIcon = walletIcon;

export const RupeeIcon = rupeeSign;
export const PaidIcon = successFilled;
export const ProjectsIcon = briefcaseIcon;
export const ProjectsClosedIcon = briefcaseCheck;
export const ProjectsOpenIcon = briefcaseUpload;
export const PaymentsIcon = moneyBag;
export const ExpensesIcon = moneyDismiss24Filled;
export const PolicyIcon = policyIcon;
export const UsersIcon = peopleFill;