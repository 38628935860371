/*
    Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import Page from 'src/components/Page';
import policyService from 'src/services/PolicyServiceClass';
import AddPolicy from './forms/AddPolicyForm';

/*
    Main Working
*/
export default ({ editing }) => {

    const [editingData, setEditingData] = useState()

    const policyID = useParams().policyID

    const getData = () => {

        policyService.getType(policyID)
            .then((data) => {
                setEditingData(data)
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        getData();
    }, []);

    console.log(!editingData, editingData, editing)


    /*
      Main Design
    */
    return (
        <Page title={editing ? `Edit Policy` : `Add Policy`} >
            <Container maxWidth="xl">
                <ListPageTitle>
                    {editing ? `Updating Policy` : `Adding Policy`}
                </ListPageTitle>
                {policyID && !editingData ?
                    <CenterLoading />
                    :
                    <AddPolicy editing={editing} editingData={editingData} policyID={policyID} />
                }
            </Container>
        </Page >
    );
};
