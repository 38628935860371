/*
  Imports
*/
import { Avatar, Grid, Stack, Typography } from '@material-ui/core';
import { DefaultAvatar } from 'src/config/settings';
import { getDateString, getDateTime } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import { getImage } from 'src/utils/misc';

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const { name, addedOn, handler, image, amount, shareable, stake, donation } = data;

  const isExpnese = name.toLowerCase().includes('expense');
  /*
    Main Design
  */
  return (
    <>
      <Grid container spacing={1} style={{ paddingBottom: 20, paddingTop: 20, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Date: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography>{getDateString(addedOn)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Type: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography>{name}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: 20, paddingTop: 20, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Handler: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={handler} src={getImage(image) || DefaultAvatar} />
            <Typography variant="subtitle" noWrap>
              {handler}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">Amount: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography>Rs. {fNumber(amount)}</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ paddingBottom: 10, paddingTop: 10, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">{isExpnese ? 'Comapany' : 'Stake'}: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography>Rs. {fNumber(stake)}</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">{isExpnese ? 'Divideable' : 'Shareable'}: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Typography>Rs. {fNumber(shareable)}</Typography>
        </Grid>
      </Grid>
      {!isExpnese && (
        <Grid container spacing={2} style={{ paddingBottom: 20, paddingTop: 10, paddingLeft: 10 }}>
          <Grid item xs={12} sm={2} md={2}>
            <Typography fontWeight="bold">Donation: </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography>Rs. {fNumber(donation)}</Typography>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Typography fontWeight="bold"> </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Typography></Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
