/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import CenterLoading from 'src/components/misc/CenterLoading';
import userService from 'src/services/UserService';
import Page from '../components/Page';
import UserProfile from './profile/UserProfile';

/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({ name: '' });
  const [sameUser, setSameUser] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState({});

  const userID = useParams().id;

  const { name } = user;

  /*
    Handlers, Functions
  */
  const getData = () => {
    const user = userService.getLoggedInUser();
    setLoggedInUser(user);
    if (userID && user.username != userID) {
      setSameUser(false);
      userService.getUser(userID)
        .then((u) => {
          setUser(u);
        })
        .catch((_err) => {
          navigate('/404');
        })
        .finally(() => setLoading(false));
    }
    else {
      setUser(user);
      setLoading(false);
    }
  };



  /*
    Use Effect Hooks.
  */
  useEffect(getData, []);

  /*
    Main Design
  */
  return (
    <Page title={sameUser ? 'Your Profile' : `Viewing ${name}`}>
      {loading ? (
        <CenterLoading />
      ) : (
        <Container maxWidth="xl">
          <UserProfile user={user} />
        </Container>
      )}
    </Page>
  );
};
