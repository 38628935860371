import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import { LogoImage } from 'src/config/settings';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box margin="auto" component="img" src={LogoImage} sx={{ width: 180, height: 'auto', ...sx }} />
  );
}
