/*
  Imports
*/
import { Grid, Typography } from '@material-ui/core';

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const { shareable, stake } = data;
  /*
    Main Design
  */
  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20, paddingTop: 20, paddingLeft: 10 }}>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">To Company: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{stake}%</Typography>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Typography fontWeight="bold">To Members: </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <Typography>{shareable}%</Typography>
        </Grid>
      </Grid>
    </>
  );
};
