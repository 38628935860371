import GenericService from './GenericService';

class ServiceClass extends GenericService {
  constructor() {
    super();
  }

  getAll = () => this.get('/payments');

  getByProject = (ID) => this.get('/payments?project=' + ID);

  getOne = (ID) => this.get(`/payments/${ID}`);

  updateTranscation = (id, paid) => this.put(`/payments/transcations/${id}`, { paid });

  updateDonation = (id, paid) => this.put(`/payments/${id}`, { paid });

  updateAllTranscations = (id, paid) => this.patch(`/payments/transcations/${id}/payall`, { paid });

  getTypes = () => this.get('/payments/types');

  calculate = (data) => this.post('/payments', data);

  add = (data) => this.post('/payments?add=true', data);

  remove = (ID) => this.delete(`/payments/${ID}`);

}

const paymentService = new ServiceClass();

export default paymentService;
