import { Badge, Box, Card } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import palette from 'src/theme/palette';

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0)
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(25),
  height: theme.spacing(25),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.secondary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.secondary.dark, 0)} 0%, ${alpha(
    theme.palette.secondary.dark,
    0.74
  )} 100%)`
}));

export default ({ Image, bod }) => {
  return (
    <RootStyle>
      <IconWrapperStyle>
        {bod ? (
          <Badge
            color="warning"
            overlap="circular"
            badgeContent="Borad Of Directors"
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <Box
              component="img"
              src={Image}
              sx={{
                borderRadius: '50%',
                width: '90%',
                height: '90%'
              }}
            />
          </Badge>
        ) : (
          <Box
            component="img"
            src={Image}
            sx={{
              borderRadius: '50%',
              width: '90%',
              height: '90%'
            }}
          />
        )}
      </IconWrapperStyle>
    </RootStyle>
  );
};
