import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import DashboardLayout from './layouts/dashboard/DashboardLayout';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import { RouteDashboard, RouteForgetPass, RouteLandingPage, RouteResetPass } from './config/routes';

import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import Logout from './pages/Logout';
import PageError from './pages/PageError';
import Profile from './pages/Profile';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';

import Dashboard from './pages/Dashboard';

import Projects from './pages/Projects';
import Payments from './pages/Payments';
import Transcation from './pages/Transcation';
import Expenses from './pages/Expenses';
import Users from './pages/Members';
import Policy from './pages/Policy';
import Divisions from './pages/Divisions';
import AddMember from './pages/AddMember';
import AddProject from './pages/AddProject';
import AddPayment from './pages/AddPayment';
import AddExpense from './pages/AddExpense';
import AddPolicy from './pages/AddPolicy';

export default function Router() {
  return useRoutes([
    {
      path: '/login',
      element: <LogoOnlyLayout loginPage />,
      children: [{ path: '', element: <Login teacher /> }]
    },
    // {
    //   path: '/secured',
    //   element: <DashboardLayout rank={1} />,
    // },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <Navigate to={RouteDashboard} /> },
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'profile', element: <Profile /> },
        { path: 'settings', element: <Settings /> },
        {
          path: 'projects',
          element: <Outlet />,
          children: [
            { path: '', element: <Projects /> },
            { path: 'add', element: <AddProject /> }
          ]
        },
        { path: 'cprojects', element: <Projects closed /> },
        {
          path: 'payments',
          element: <Outlet />,
          children: [
            { path: '', element: <Payments /> },
            { path: ':id', element: <Transcation /> },
            { path: 'add', element: <AddPayment /> }
          ]
        },
        {
          path: 'expenses',
          element: <Outlet />,
          children: [
            { path: '', element: <Expenses /> },
            { path: ':id', element: <Transcation expense /> },
            { path: 'add', element: <AddExpense /> }
          ]
        },
        {
          path: 'users',
          element: <Outlet />,
          children: [
            { path: '', element: <Users /> },
            { path: ':id', element: <Profile /> },
            { path: 'add', element: <AddMember /> }
          ]
        },
        {
          path: 'policy',
          element: <Outlet />,
          children: [
            { path: '', element: <Policy /> },
            { path: 'add', element: <AddPolicy /> },
            { path: 'edit/:policyID', element: <AddPolicy editing={true} /> },
            { path: ':id', element: <Divisions /> }
          ]
        }
      ]
    },
    {
      path: RouteLandingPage,
      element: <Navigate to={RouteDashboard} replace />
    },
    {
      path: RouteLandingPage,
      element: <LogoOnlyLayout />,
      children: [
        { path: 'logout', element: <Logout /> },
        { path: '404', element: <PageError e404 /> },
        { path: '401', element: <PageError /> },
        // { path: '*', element: <Navigate to="/404" /> },
        { path: RouteForgetPass, element: <ForgetPassword /> },
        { path: RouteResetPass, element: <ResetPassword /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
