/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/*
  Imports:
    Our Imports
    Components and Settings
*/
import AreaCharts from 'src/components/charts/AreaCharts';
import PieDonutChart from 'src/components/charts/PieDonutChart';
import TextLogoCard from 'src/components/misc/cards/TextLogoCard';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import { RupeeIcon } from 'src/config/icons';
import stakeService from 'src/services/StakeServiceClass';
import statsService from 'src/services/StatsServiceClass';
import userService from 'src/services/UserService';
import palette from 'src/theme/palette';
import { getMonthName } from 'src/utils/dateTime';
import Page from '../components/Page';
import UserCards from './body/UserCards';

/*
  Main Working
*/
export default () => {
  /*
    States, Params, Navigation, Query, Variables.
  */

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const pageName = 'Dashboard';

  /*
    Handlers, Functions
  */
  const [data, setData] = useState();
  const [mData, setMData] = useState();

  const [mbData, setMbData] = useState();

  const [nBodData, setnBodData] = useState();

  const [inBank, setInBank] = useState();

  const user = userService.getLoggedInUser();

  const getData = async () => {
    setLoading(true);

    if (user.rank > 0)
      statsService
        .getInBank()
        .then((res) => {
          setInBank(+res.amount);
        })
        .catch((err) => {
          console.log('Error from API Bank', err, err?.response);
        });

    stakeService
      .getBod()
      .then((data) => {
        console.log('Data from API', data);
        setData(data);
      })
      .catch((err) => {
        if (err.response?.status === 401) navigate('/logout');
        console.log('Error from API', err, err?.response);
        //TODO: improve error handling here
      })
      .finally(() => {
        setLoading(false);
      });

    statsService
      .getMonthlyMoney()
      .then((data) => {
        console.log('Data from API', data);
        setMData(data);
      })
      .catch((err) => {
        if (err.response?.status === 401) navigate('/logout');
        console.log('Error from API', err, err?.response);
        //TODO: improve error handling here
      })
      .finally(() => {
        setLoading(false);
      });

    statsService
      .getMonthlyBestUsers()
      .then((data) => {
        console.log('Data from API', data);
        setMbData(data);
      })
      .catch((err) => {
        if (err.response?.status === 401) navigate('/logout');
        console.log('Error from API', err, err?.response);
        //TODO: improve error handling here
      })
      .finally(() => {
        setLoading(false);
      });

    stakeService
      .getNonBod()
      .then((data) => {
        console.log('Data from API', data);
        setnBodData(data);
      })
      .catch((err) => {
        if (err.response?.status === 401) navigate('/logout');
        console.log('Error from API', err, err?.response);
        //TODO: improve error handling here
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
    Use Effect Hooks.
  */
  useEffect(getData, []);

  const stakes = data?.map((u) => +u.stakeP);
  const labels = data?.map((u) => u.name);

  const nBodStakes = nBodData?.map((u) => +u.stakeP);
  const nBodLabels = nBodData?.map((u) => u.name);

  const mLabels = [];
  const earned = [];
  const spent = [];
  if (mData) {
    for (let i = mData.length - 1; i >= 0; i--) {
      const m = mData[i];
      mLabels.push(`${getMonthName(m.month)} ${m.year}`);
      earned.push(m.earned);
      spent.push(m.spent);
    }
  }

  const chartData = [
    {
      name: 'Spendings',
      type: 'bar',
      data: spent,
      color: palette.error.main
    },
    {
      name: 'Earnings',
      type: 'area',
      data: earned,
      color: palette.success.main
    }
  ];

  /*
    Main Design.
  */
  return (
    <Page title={pageName}>
      <Container>
        <ListPageTitle>{pageName}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <>
            {inBank && (
              <TextLogoCard
                icon={RupeeIcon}
                color={'#fff'}
                backgroundColor={palette.success.darker}
                title={'Total In Bank'}
                amount={inBank}
              />
            )}
            <br />
            {data && (
              <PieDonutChart symbol="%" title="Current Stake" labels={labels} data={stakes} />
            )}
            <br />
            {mData && (
              <AreaCharts
                title={'Monthly Spending/Earning Details'}
                labels={mLabels}
                data={chartData}
                type={['solid', 'gradient']}
              />
            )}
            <br />
            {mbData && <UserCards title="Top Monthly Earners" data={mbData} />}
            <br />
            {nBodData && (
              <PieDonutChart
                symbol="%"
                title="Contribituons by non BOD"
                labels={nBodLabels}
                data={nBodStakes}
              />
            )}
            <br />
          </>
        )}
      </Container>
    </Page>
  );
};
