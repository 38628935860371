/*
	Imports
*/
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/*
	Imports:
		Material UI
*/
import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Grid, IconButton, InputAdornment, InputLabel, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
/*
	Imports:
		Our Imports
		Components and Settings
		Services
*/
import AlertSnackbar from 'src/components/misc/alerts/AlertSnackbar';
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddUserSchema } from 'src/config/form-schemas';
import { HidePasswordIcon, PasswordIcon, ShowPasswordIcon } from 'src/config/icons';
import { RouteUsers } from 'src/config/routes';
import { acceptImageUpload } from '../../config/settings';
import userService from '../../services/UserService';
import { ContentStyle, FormTheme } from '../../theme/form-pages';


/*
	Main Working
*/
export default () => {

	/*
		States, Params, Navigation, Query, Variables.
	*/
	const [serverError, setServerError] = useState('');
	const [openDia, setOpenDia] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [imageUrl, setImageUrl] = useState(null);
	const [imageID, setImageID] = useState(null);
	const [alreadyRegistered, setAlreadyRegistered] = useState(false);
	const [wrongFile, setWrongFile] = useState(false);
	const [showPassword, setShowPassword] = useState(false);


	const coordinatorID = useParams().coordinatorID;

	const navigate = useNavigate();

	/*
		Form Setup
	*/
	const formik = useFormik({
		initialValues: {
			name: '',
			username: '',
			email: '',
			password: '',
			dob: ''
		},
		validationSchema: AddUserSchema,
		onSubmit: (_values, { setFieldError }) => {

			addData();
		}
	});

	const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

	/*
		Handlers
	*/
	const addData = () => {
		setSubmitting(true);
		userService.addUser({ ...values }, selectedImage)
			.then(() => {
				console.log("User Added");
				setOpenDia(true);
			})
			.catch((err) => {
				console.log("Error", err, err.response);
				if (err.response) {
					for (const key in values) {
						if (Object.hasOwnProperty.call(values, key)) {
							if (err.response.data.message.includes(key))
								setFieldError(key, 'Invalid or Already Added');
						}
					}
				}
				if (err.fileUploadError) {
					setImageUrl(loggedInUser.image);
					setWrongFile(err.msg);
					setTimeout(() => setWrongFile(false), hideFileAlertIn);
				}
				setServerError('An Error Occured');


				console.log("Error", err, err.response);
			}).finally(() => {
				setSubmitting(false);
			});
	};

	const handleClose = () => {
		setOpenDia(false);
		navigate(RouteUsers);
	};

	const handleImageChange = () => {

		if (selectedImage) {
			setImageUrl(URL.createObjectURL(selectedImage));
		}
	};

	const handleShowPassword = () => {
		setShowPassword((show) => !show);
	};
	const handlePasswordUpdate = () => {
		setPassword(values.password);
	};

	/*
		Use Effect Hooks.
	*/
	useEffect(handleImageChange, [selectedImage]);

	/*
		Main Design
	*/
	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>

				<Typography variant="h6" gutterBottom>
					Personal Details
				</Typography>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Name</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="name"
								{...getFieldProps('name')}
								error={Boolean(touched.name && errors.name)}
								helperText={touched.name && errors.name}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Username</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="username"
								{...getFieldProps('username')}
								error={Boolean(touched.username && errors.username)}
								helperText={touched.username && errors.username}
							/>
						</Grid>
					</Grid>
				</ContentStyle>

				<ContentStyle>
					<Grid container spacing={3}>

						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Email</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="email"
								{...getFieldProps('email')}
								inputProps={{
									inputMode: 'email'
								}}
								error={Boolean(touched.email && errors.email)}
								helperText={touched.email && errors.email}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel label="Address">Date Of Birth</InputLabel>
							</ThemeProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									inputFormat="yyyy/MM/dd"
									mask="____/__/__"
									value={values.dob}
									disabled={alreadyRegistered}
									maxDate={new Date()}
									onChange={(newValue) => {
										setFieldValue('dob', newValue);
									}}
									renderInput={(params) => <TextField
										fullWidth
										{...params}
										{...getFieldProps('dob')}

										error={Boolean(touched.dob && errors.dob)}
										helperText={touched.dob && errors.dob}
									/>}
								/>
							</LocalizationProvider>

						</Grid>
					</Grid>
				</ContentStyle>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Bank</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="bank"
								{...getFieldProps('bank')}
								error={Boolean(touched.bank && errors.bank)}
								helperText={touched.bank && errors.bank}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Account #</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="account"
								{...getFieldProps('account')}
								inputProps={{
									inputMode: 'numeric'
								}}
								error={Boolean(touched.account && errors.account)}
								helperText={touched.account && errors.account}
							/>
						</Grid>
					</Grid>
				</ContentStyle>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>

							<input
								accept={acceptImageUpload}
								type="file"
								id="select-image"
								style={{ display: 'none' }}
								onChange={e => setSelectedImage(e.target.files[0])}
							/>
							<label htmlFor="select-image">
								<Button
									variant="outlined" color='primary' component="span">
									Upload Image
								</Button>
							</label>
							{(
								imageUrl && <Box mt={2} textAlign="center">
									<Avatar alt={values.name} src={imageUrl} />
								</Box>
							)}

						</Grid>

					</Grid>
				</ContentStyle>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>New Password</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								type={showPassword ? 'text' : 'password'}
								{...getFieldProps('password')}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton onClick={handleShowPassword} edge="end">
												<Icon icon={showPassword ? HidePasswordIcon : ShowPasswordIcon} />
											</IconButton>
										</InputAdornment>
									),
									startAdornment: (
										<InputAdornment position="start">
											<Icon icon={PasswordIcon} inline="true" style={{ fontSize: 20 }} />
										</InputAdornment>
									)
								}}
								error={Boolean(touched.password && errors.password)}
								helperText={touched.password && errors.password}

							/>
						</Grid>
					</Grid>
				</ContentStyle>

				<Dialog
					buttonText={"Close"}
					openDialog={openDia}
					handleButton={handleClose}
				>
					{`User is added`}
				</Dialog>
				<AlertSnackbar severity="warning" open={wrongFile}>
					Extenstion not allowed
				</AlertSnackbar>

				<LoadingFormButton loading={isSubmitting}>
					{'Add'}
				</LoadingFormButton>
				<ServerError open={serverError}>
					{serverError}
				</ServerError>
			</Form>
		</FormikProvider >
	);
};

