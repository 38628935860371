/*
	Imports
*/
import { FieldArray, Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router-dom';

/*
	Imports:
		Material UI
*/
import { Icon } from '@iconify/react';
import { Grid, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
/*
	Imports:
		Our Imports
		Components and Settings
		Services
*/
import Dialog from 'src/components/misc/alerts/Dialog';
import ServerError from 'src/components/misc/alerts/ServerError';
import LoadingFormButton from 'src/components/misc/Buttons/LoadingFormButton';
import { AddExpenseSchema } from 'src/config/form-schemas';
import { RupeeIcon } from 'src/config/icons';
import { RouteExpense } from 'src/config/routes';
import expenseService from 'src/services/ExpenseServiceClass';
import { ContentStyle, FormTheme } from '../../theme/form-pages';
import ExpensesAddHeader from '../header/ExpensesAddHeader';
import TranscationsAddList from './tables/TranscationsAddList';


/*
	Main Working
*/
export default ({ types, projects, users }) => {

	/*
		States, Params, Navigation, Query, Variables.
	*/
	const [serverError, setServerError] = useState('');
	const [openDia, setOpenDia] = useState(false);

	const [calculated, setCalculated] = useState(false);

	const navigate = useNavigate();

	/*
		Form Setup
	*/
	const formik = useFormik({
		initialValues: {
			info: '',
			type: types[0].id,
			project: 0,
			addedOn: '',
			amount: 0,
			details: [{ user: 0 }]
		},
		validationSchema: AddExpenseSchema,
		onSubmit: (_values, { setFieldError }) => {

			addData();
		}
	});

	const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue, setSubmitting, resetForm, initialValues, setFieldError } = formik;

	/*
		Handlers
	*/
	const addData = () => {
		setSubmitting(true);
		if (calculated) {
			return expenseService.add({ ...values })
				.then(() => {
					setOpenDia(true);
				})
				.catch((err) => {
					console.log("Error adding payment", err, err.response);

				}).finally(() => {
					setSubmitting(false);
				});
		}
		expenseService.calculate({ ...values })
			.then((data) => {
				for (const t of data.transcations) {
					const user = users.find((u) => u.id == t.user);
					if (user) {
						t.image = user.image;
						t.name = user.name;
					}
				}
				setCalculated(data);
			})
			.catch((err) => {
				console.log("Error", err, err.response);
				if (err.response) {
					for (const key in values) {
						if (Object.hasOwnProperty.call(values, key)) {
							if (err.response.data.message.includes(key))
								setFieldError(key, 'Invalid or Already Added');
						}
					}
				}

				console.log("Error", err, err.response);
			}).finally(() => {
				setSubmitting(false);
			});
	};

	const handleClose = () => {
		setOpenDia(false);
		navigate(RouteExpense);
	};


	const handleUsersChange = () => {
		for (const d of values.details) {
			if (d.user == 0)
				return;
		}
		const lastIndex = values.details.length - 1;
		if (values.details[lastIndex].user) {
			const u = [...values.details];
			u.push({ user: 0 });
			setFieldValue('details', u);
		}

		setServerError("");
	};

	const handleChangeInAny = () => {
		setCalculated(false);
	};

	/*
		Use Effect Hooks.
	*/

	useEffect(handleUsersChange, [values.details]);

	useEffect(handleChangeInAny, [values]);

	/*
		Main Design
	*/

	return (
		<FormikProvider value={formik}>
			<Form autoComplete="off" noValidate onSubmit={handleSubmit}>

				<Typography variant="h6" gutterBottom>
					Expense Details
				</Typography>

				<ContentStyle>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Info</InputLabel>
							</ThemeProvider>
							<TextField
								fullWidth
								autoComplete="info"
								{...getFieldProps('info')}
								error={Boolean(touched.info && errors.info)}
								helperText={touched.info && errors.info}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel label="type">Expense Type</InputLabel>
							</ThemeProvider>
							<Select fullWidth
								{...getFieldProps('type')}
								error={Boolean(touched.type && errors.type)}
							>
								{
									types.map(row => (
										<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
									))
								}

							</Select>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel label="project">Project</InputLabel>
							</ThemeProvider>
							<Select fullWidth
								{...getFieldProps('project')}
								error={Boolean(touched.project && errors.project)}
							>
								<MenuItem key={0} value={0}>NONE</MenuItem>
								{
									projects.map(row => (
										<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
									))
								}

							</Select>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel>Amount</InputLabel>
							</ThemeProvider>
							<NumberFormat
								fullWidth
								customInput={TextField}
								type="text"
								allowEmptyFormatting="true"
								inputProps={{
									inputMode: 'numeric',
								}}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Icon icon={RupeeIcon} inline="true" style={{ fontSize: 25 }} />
										</InputAdornment>
									)
								}}
								{...getFieldProps('amount')}

								error={Boolean(touched.amount && errors.amount)}
								helperText={touched.amount && errors.amount}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6}>
							<ThemeProvider theme={FormTheme}>
								<InputLabel label="addedOn">Date</InputLabel>
							</ThemeProvider>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DatePicker
									inputFormat="yyyy/MM/dd"
									mask="____/__/__"
									value={values.addedOn}
									maxDate={new Date()}
									onChange={(newValue) => {
										setFieldValue('addedOn', newValue);
									}}
									renderInput={(params) => <TextField
										fullWidth
										{...params}
										{...getFieldProps('addedOn')}

										error={Boolean(touched.addedOn && errors.addedOn)}
										helperText={touched.addedOn && errors.addedOn}
									/>}
								/>
							</LocalizationProvider>
						</Grid>
					</Grid>
				</ContentStyle>
				<ContentStyle>
					<Grid container spacing={3}>
						<FieldArray name="users">
							<>
								{values.details.map((user, index) =>

									<Grid item xs={12} sm={6} md={6}>
										<ThemeProvider theme={FormTheme}>
											<InputLabel>User</InputLabel>
										</ThemeProvider>
										<Select fullWidth
											{...getFieldProps(`details.${index}.user`)}
										>
											<MenuItem key={0} value={0}>NONE</MenuItem>
											{
												users.map(row => (
													<MenuItem key={row.id} value={row.id}>{row.name}</MenuItem>
												))
											}

										</Select>
									</Grid>
								)}
							</>
						</FieldArray>
					</Grid>
				</ContentStyle>

				{!!calculated &&
					<ContentStyle >
						<ExpensesAddHeader data={{ ...calculated, amount: values.amount }} />
						<TranscationsAddList data={calculated?.transcations} />
					</ContentStyle>
				}

				<Dialog
					buttonText={"Close"}
					openDialog={openDia}
					handleButton={handleClose}
				>
					{`Expense is added`}
				</Dialog>

				<LoadingFormButton disabled={serverError} loading={isSubmitting}>
					{calculated ? 'Add' : 'Calculate'}
				</LoadingFormButton>
				<ServerError open={serverError}>
					{serverError}
				</ServerError>
			</Form>
		</FormikProvider >
	);
};

