import axios from 'axios';
import { allowedExtensions, BackendURL, BackendURLAPI, maxUploadFileSize } from 'src/config/settings';
import renameFile from 'src/utils/renameFile';

axios.defaults.baseURL = BackendURLAPI;
axios.defaults.withCredentials = true;
axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8' || 'application/json;';
class GenericService {
  tokenUpdate = () => {
    const token = localStorage.getItem('token');
    if (token)
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    else
      delete axios.defaults.headers.common.Authorization;
  };

  get = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .get(url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  post = (url, data) =>
    new Promise((resolve, reject) => {
      console.log("JSON data", data);
      axios
        .post(url, JSON.stringify(data))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });

  postL = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .post(url, JSON.stringify(data), {
          transformRequest: [function (data_, headers) {
            delete headers.Authorization;
            delete headers.common.Authorization;
            return data_;
          }],
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.warn(err);
          reject(err);
        });
    });


  delete = (url) =>
    new Promise((resolve, reject) => {
      axios
        .delete(url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  put = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .put(url, JSON.stringify(data))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  patch = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .patch(url, JSON.stringify(data))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  prepareForm(data, file) {
    let formData = new FormData();

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        formData.append(key, element);
      }
    }
    if (file) {
      let extension = file.name.split(".");
      extension = extension[extension.length - 1];

      if (file.size > maxUploadFileSize) {
        return Promise.reject({ fileUploadError: true, msg: `Maximum ${maxUploadFileSize / 1024 / 1024} MB file allowed` });
      }

      if (!allowedExtensions.includes(extension)) {
        return Promise.reject({ fileUploadError: true, msg: 'Extension not allowed' });
      }

      formData.append("image", file);
    }
    return formData;
  }

  postForm(url, data, file, onUploadProgress) {
    const formData = this.prepareForm(data, file);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  putForm(url, data, file, onUploadProgress) {
    const formData = this.prepareForm(data, file);

    return axios.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  deleteFile(file) {
    return axios.delete(`upload/files/${file}`);
  }



  getTotalCount(response) {
    const { meta } = response;
    let count = 0;
    if (meta) {
      const { pagination } = meta;
      if (pagination)
        count = pagination.total;
    }
    return count;
  }

  download(url, name) {
    axios({
      url,
      method: 'GET',
      responseType: 'blob'
    })
      .then((response) => {
        const url_ = window.URL
          .createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url_;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }
}
export default GenericService;
