/*
  Imports
*/
import { Container } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';



import { useEffect, useState } from 'react';
import FloatingAdd from 'src/components/misc/Buttons/FloatingAdd';
import CenterLoading from 'src/components/misc/CenterLoading';
import ListPageTitle from 'src/components/misc/ListPageTitle';
import projectService from 'src/services/ProjectServiceClass';
import Page from '../components/Page';
import ProjectsList from './tables/ProjectsList';

/*
  Main Working
*/
export default ({ closed }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const title = closed ? "Closed Projects" : "Projects";

  /*
      Handlers, Functions
    */
  const getData = () => {
    setLoading(true);
    const f = closed ? projectService.getClosed : projectService.getActive;
    f()
      .then((res) => {
        console.log("Res", res);
        setData(res);
      })
      .catch((error) => {
        if (error.response) if (error.response.status === 401) navigate('/401');
        navigate('/404');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  /*
      Use Effect Hooks.
    */
  useEffect(getData, [closed]);

  /*
    Handlers, Functions
  */
  const handleAdd = () => {
    navigate('./add');
  };

  /*
    Main Design
  */
  return (
    <Page title={title}>
      <Container>
        <ListPageTitle >{title}</ListPageTitle>
        {loading ? (
          <CenterLoading />
        ) : (
          <ProjectsList data={data} reload={getData} />
        )}
        {!closed && <FloatingAdd tooltip='add new project' onClick={handleAdd} />}
      </Container>
    </Page>
  );
};
