/*
  Imports
*/
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';

import { getDateString } from 'src/utils/dateTime';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
  let toSort = array;
  if (query) {
    toSort = filter(
      array,
      (element) =>
        element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        element.category.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        String(element.amount).toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data }) => {
  /*
    States, Params, Navigation, Query, Variables.
  */
  const TableHeader = [
    { id: 'name', label: 'Type', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'amount', label: 'Percentage', align: 'center' }
  ];

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState(TableHeader[1].id);
  const [filterName, setFilterName] = useState('');

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
  const isUserNotFound = filteredUsers.length === 0;

  /*
    Handlers, Functions
  */
  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  /*
    Main Design.
  */
  return (
    <Card>
      <UserListToolbar filterName={filterName} onFilterName={handleFilterByName} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <UserListHead
              order={order}
              orderBy={orderBy}
              headLabel={TableHeader}
              rowCount={data.length}
              onRequestSort={handleRequestSort}
              hideCheckBoxes={true}
            />
            <TableBody>
              {filteredUsers.map((row) => {
                const { id, name, category, amount } = row;

                return (
                  <TableRow key={id} tabIndex={-1} role="checkbox">
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell>
                      <Typography variant="subtitle2" noWrap>
                        {name}
                      </Typography>
                    </TableCell>

                    <TableCell align="left">{category}</TableCell>
                    <TableCell align="center">{amount}%</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {isUserNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
};
