/*
  Imports
*/
import {
    Avatar,
    Card,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography
} from '@material-ui/core';
import { filter } from 'lodash';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import Dialog from 'src/components/misc/alerts/Dialog';
import Label from 'src/components/misc/Label';
import { PaidIcon, TrashIcon } from 'src/config/icons';
import { DefaultAvatar, defaultPerPage, rowsPerPageList } from 'src/config/settings';
import paymentService from 'src/services/PaymentServiceClass';
import userService from 'src/services/UserService';
import palette from 'src/theme/palette';
import { getDateString } from 'src/utils/dateTime';
import { fNumber } from 'src/utils/formatNumber';
import { getImage } from 'src/utils/misc';
import { getComparator, stabilize } from 'src/utils/table';
import SearchNotFound from '../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/misc/table';
import Scrollbar from '../../components/Scrollbar';

/*
  Global Variables, Functions
*/

function applySortFilter(array, comparator, query) {
    let toSort = array;
    if (query) {
        toSort = filter(
            array,
            (element) =>
                element.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.handler.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                element.project.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                String(element.totalAmount).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                getDateString(element.addedOn).toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
                (!element.unpaid ? 'no' : 'unpaid').indexOf(query.toLowerCase()) !== -1
        );
    }
    return stabilize(toSort, comparator);
}

/*
  Main Working
*/
export default ({ data, reload }) => {
    /*
    States, Params, Navigation, Query, Variables.
  */

    const TableHeader = [
        { id: 'project', label: 'Project', align: 'left' },
        { id: 'addedOn', label: 'Date', align: 'left' },
        { id: 'name', label: 'Type', align: 'left' },
        { id: 'handler', label: 'Finder', align: 'left' },
        { id: 'amount', label: 'Amount', align: 'center' },
        { id: 'shareable', label: 'Shared', align: 'center' },
        { id: 'stake', label: 'Stake', align: 'center' },
        { id: 'donationLeft', label: 'Donation', align: 'center' },
        { id: 'unpaid', label: 'Unpaid', align: 'center' }
    ];

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('desc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState(TableHeader[1].id);
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(defaultPerPage);

    const [openDia, setOpenDia] = useState(false);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
    const isUserNotFound = filteredUsers.length === 0;

    const loggedInUser = userService.getLoggedInUser();

    console.log('Data for payments', data);

    /*
    Handlers, Functions
  */
    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleMoreMenuCell = (event) => {
        event.preventDefault();
    };

    const showDeleteDialog = (ID) => {
        setOpenDia(ID);
    };

    const handleDelete = () => {
        paymentService
            .remove(openDia)
            .then(reload)
            .catch((err) => console.log(err));
        setOpenDia(false);
    };

    const handlePayDonation = (ID) => {
        paymentService
            .updateDonation(ID, 1)
            .then(reload)
            .catch((err) => console.log(err.response));
    };

    const handleClose = () => {
        setOpenDia(false);
    };

    const deleteObject = { text: 'Delete', icon: TrashIcon, event: showDeleteDialog, id: 0 };
    const donationObject = {
        text: 'Pay Donation',
        icon: PaidIcon,
        event: handlePayDonation,
        id: 0
    };

    /*
    Main Design.
  */
    return (
        <>
            <Card>
                <UserListToolbar
                    numSelected={selected.length}
                    filterName={filterName}
                    onFilterName={handleFilterByName}
                />
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TableHeader}
                                rowCount={data.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                                hideCheckBoxes={true}
                            />
                            <TableBody>
                                {filteredUsers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const {
                                            id,
                                            project,
                                            name,
                                            handler,
                                            addedOn,
                                            amount,
                                            stake,
                                            shareable,
                                            donation,
                                            paidDonation,
                                            unpaid,
                                            image,
                                            handlerId
                                        } = row;
                                        console.log('Unpaid', unpaid);
                                        const isItemSelected = selected.indexOf(id) !== -1;

                                        const MORE_MENU = [];

                                        if (!paidDonation) MORE_MENU.push(donationObject);

                                        if (handlerId == loggedInUser.id)
                                            MORE_MENU.push(deleteObject);

                                        // if(loggedInUser. != )
                                        return (
                                            <TableRow
                                                hover
                                                component={Link}
                                                to={`./${id}`}
                                                key={id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                                style={{
                                                    backgroundColor: palette.background.hover,
                                                    textDecoration: 'none'
                                                }}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, name)}
                              /> */}
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="subtitle2" noWrap>
                                                        {project}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {' '}
                                                    {getDateString(addedOn)}
                                                </TableCell>
                                                <TableCell align="left"> {name}</TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <Stack
                                                        direction="row"
                                                        alignItems="center"
                                                        spacing={2}
                                                    >
                                                        <Avatar
                                                            alt={handler}
                                                            src={getImage(image) || DefaultAvatar}
                                                        />
                                                        <Typography variant="subtitle" noWrap>
                                                            {handler}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center">
                                                    Rs. {fNumber(amount)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    Rs. {fNumber(shareable)}
                                                </TableCell>
                                                <TableCell align="center">
                                                    Rs. {fNumber(stake)}
                                                </TableCell>
                                                {/* <TableCell align="center">Rs. {fNumber(donation)}</TableCell> */}

                                                <TableCell align="center">
                                                    <Label
                                                        variant="ghost"
                                                        color={
                                                            !paidDonation == 0 ? 'success' : 'error'
                                                        }
                                                    >
                                                        {!paidDonation == 0
                                                            ? `paid`
                                                            : `Rs. ${fNumber(donation)}`}
                                                    </Label>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Label
                                                        variant="ghost"
                                                        color={!unpaid ? 'success' : 'error'}
                                                    >
                                                        {!unpaid
                                                            ? 'paid'
                                                            : `Rs. ${fNumber(unpaid)}`}
                                                    </Label>
                                                </TableCell>
                                                {MORE_MENU.length > 0 && (
                                                    <TableCell
                                                        align="right"
                                                        onClick={handleMoreMenuCell}
                                                    >
                                                        <UserMoreMenu
                                                            ID={[id]}
                                                            Options={MORE_MENU}
                                                        />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={rowsPerPageList}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            <Dialog
                buttonText={'Close'}
                buttonText2={'Delete'}
                openDialog={openDia}
                handleButton={handleClose}
                handleButton2={handleDelete}
                warning
                icon={TrashIcon}
            >
                {`Are you sure you want to delete the payment`}
            </Dialog>
        </>
    );
};
