import { Box, Card, CardHeader, Grid } from "@material-ui/core";
import React from "react";
import UserLogoCard from "src/components/misc/cards/UserLogoCard";
import palette from "src/theme/palette";
import { getMonthName } from "src/utils/dateTime";


export default ({ title, data }) => {


	return (
		<Card>
			<CardHeader title={title} />
			<Box sx={{ p: 3, pb: 1 }} dir="ltr">
				<Grid container spacing={2}>
					{React.Children.toArray((
						data.map((u, index) =>
							<Grid item xs={12} sm={4} md={4}>
								<UserLogoCard color={'#fff'} backgroundColor={palette.charColors[index]} title={`${getMonthName(u.month)} ${u.year}`} name={u.name} amount={+u.amount} image={u.image} />
							</Grid>
						)
					))}
				</Grid>
				<br />
			</Box>
		</Card>
	);

};